<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container bg-hp-white dark:bg-gray-800 rounded-2xl">

          <div class="w-full h-full text-center">
            <div class="flex h-full flex-col justify-between">
              <span class="mt-4 text-blue-600 dark:text-white text-5xl">
                <i :class="`fas fa-${icon}`" />
              </span>
              <p class="text-gray-800 dark:text-gray-200 text-xl font-bold mt-4">
                  <slot name="header"/>
              </p>
              <p class="text-gray-600 dark:text-gray-400 text-sm p-2">
                  <slot name="body"/>
              </p>
              <div class="flex items-center justify-between gap-4 w-full mt-8">
                <button type="button" @click="$emit('close')" class="py-2 px-4 bg-blue-600 hover:bg-blue-700 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none rounded-lg">
                    Close
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    icon: String
  },
  methods: {
    buttonPressed() {
      this.method();
    },
  }
}
</script>

<style scoped>
@media only screen and (min-height: 400px) {
    .modal-container {
      width: 44rem;
    }
}

@media only screen and (min-height: 700px) {
    .modal-container {
      width: 25rem;
    }
}


@media only screen and (min-height: 900px) {
    .modal-container {
      width: 20rem;
    }
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.12);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  margin: 0px auto;
  padding: 20px 23px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
}

.footer {
  justify-content: flex-end;
  display: flex;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>