<template>
  <div class="bg-hp-gray" style="min-height: 100vh;">
    <header class="w-full">
      <Menu class="bg-hp-gray" />
    </header>


    <!-- Main content -->
    <div class="pt-36 pb-80 max-w-5xl lg:max-w-6xl 2xl:max-w-8xl mx-auto px-4" style="min-height: 100vh;">
      <div class="text-center text-blue-900 font-bold text-4xl mb-16">
        Pricing
      </div>


      <div class="grid md:grid-cols-2 mx-auto max-w-4xl gap-6 mb-24">
        <!-- Free tier -->
        <div>
          <div class="relative rounded-lg shadow-md bg-hp-white dark:bg-gray-800 overflow-hidden" :class="{ 'active': free.isExpanded }">
            <!-- Main square -->
            <div class="rounded pb-6 dark:bg-gray-800" style="background: #2D7CFF">
              <div class="px-2 pt-4 pb-8 sm:p-10 sm:pb-6">
                <div class="flex flex-col gap-4 items-center">
                  <div class="text-xl text-white">Self Manage</div>
                  <span class="font-bold  text-center h-16 inline-flex px-3 py-1 text-white rounded-full text-xl leading-6 tracking-wide capitalize">
                    Free
                  </span>
                </div>

                <div class="py-11 flex justify-center text-6xl leading-none font-extrabold text-white">
                    $0
                  <span class="ml-1 pt-8 text-xl leading-8 font-medium text-white">
                    / month
                  </span>
                </div>
              </div>

              <div class="flex justify-center mt-2 text-white">
                <button @click="free.isExpanded = true" v-if="!free.isExpanded" class="hover:bg-white hover:text-black transition duration-200 ease-in-out py-2 px-3 rounded flex gap-2 items-center focus:outline-none">
                  See plan details
                  <img class="h-4" src="https://img.icons8.com/ios-glyphs/60/ffffff/chevron-down.png"/>
                </button>

                <button @click="free.isExpanded = false" v-else class="hover:bg-white hover:text-black transition duration-200 ease-in-out py-2 px-3 rounded flex gap-2 items-center focus:outline-none">
                  Hide plan details
                  <img class="h-4" src="https://img.icons8.com/ios-glyphs/60/ffffff/chevron-up.png"/>
                </button>
              </div>
            </div>

            <!-- Feature list -->
            <div v-if="free.isExpanded" class="px-6 pt-2 pb-4 sm:p-10 sm:pt-6">
              <ul>
                <TierDetail :detail="detail" v-for="(detail, index) in free.details" :key="`detail-${index}`"/>
              </ul>

              <div class="pt-6 text-sm">
                *Nominal fee per bill
              </div>
            </div>
          </div>
        </div>


        <!-- Concierge -->
        <div>
          <div class="relative rounded-lg shadow-md bg-hp-white dark:bg-gray-800 overflow-hidden" :class="{ 'active': basic.isExpanded }">
            <!-- Main square -->
            <div class="rounded pb-6 dark:bg-gray-800" style="background: #F92C61">
              <div class="flex flex-col gap-2 px-2 pt-4 pb-8 sm:p-10 sm:pb-6">
                <div class="flex flex-col gap-4 items-center">
                  <div class="text-xl text-white">Concierge</div>
                  <span class="font-bold text-center h-16 inline-flex px-3 py-1 text-white rounded-full text-xl leading-6 tracking-wide capitalize">
                    Home Manager
                  </span>
                </div>

                <div class="flex py-10 justify-center text-6xl leading-none font-extrabold text-white">
                  Varies
                </div>
              </div>

              <div class="flex justify-center mt-2 text-white">
                <button @click="basic.isExpanded = true" v-if="!basic.isExpanded" class="hover:bg-white hover:text-black transition duration-200 ease-in-out py-2 px-3 rounded flex gap-2 items-center focus:outline-none">
                  See plan details
                  <img class="h-4" src="https://img.icons8.com/ios-glyphs/60/ffffff/chevron-down.png"/>
                </button>

                <button @click="basic.isExpanded = false" v-else class="hover:bg-white hover:text-black transition duration-200 ease-in-out py-2 px-3 rounded flex gap-2 items-center focus:outline-none">
                  Hide plan details
                  <img class="h-4" src="https://img.icons8.com/ios-glyphs/60/ffffff/chevron-up.png"/>
                </button>
              </div>
            </div>

            <!-- Feature list -->
            <div v-if="basic.isExpanded" class="px-6 pt-2 pb-8 sm:p-10 sm:pt-6">
              <div class="flex items-center gap-2 pt-4 font-bold dark:text-white">
                Everything included in the free tier, plus:
              </div>

              <ul>
                <TierDetail :detail="detail" v-for="(detail, index) in lifetime.details" :key="`detail-${index}`"/>
              </ul>
            </div>
          </div>
        </div>
      </div>



      <div class="lg:col-span-3 grid md:grid-cols-3 gap-4 bg-hp-white dark:bg-gray-800 rounded-t-lg shadow-sm border-b-1 border-gray-200 dark:border-gray-600">
        <!-- Main square -->
        <div class="col-span-2 sm:col-span-1 flex flex-col gap-4 justify-between py-12 items-center rounded dark:bg-gray-800" style="background: #8C46FF">
          <span class="px-3 py-2 text-sm font-bold rounded-full bg-white h-9" style="color: #8C46FF">
            Concierge
          </span>

          <div>
            <span class="text-center h-12 inline-flex px-3 py-1 text-white rounded-full text-xl leading-6 tracking-wide capitalize">
              Currently Serving
            </span>
            
            <div class="flex justify-center text-6xl leading-none font-extrabold text-white">
              Cities
            </div>
          </div>

          <div />
        </div>
      
        <div class="col-span-2">
          <div class="flex items-center gap-2 pt-8 px-3">
            <div class="font-bold dark:text-white">Put your home on autopilot & experience homeownership like a renter</div>
          </div>

          <div class="grid grid-cols-2 gap-4">
            <ul class="pl-4 py-2">
              <TierDetail :detail="detail" v-for="(detail, index) in [
                { text: 'St. Louis City, MO', icon: false },
                { text: 'St. Louis County, MO', icon: false },
                { text: 'Las Vegas, NV', icon: false },
                { text: 'San Francisco, CA', icon: false },
                { text: 'Oakland, CA', icon: false },
                { text: 'Berkeley, CA', icon: false },
                { text: 'San Jose, CA', icon: false },
                { text: 'Sunnyvale, CA', icon: false },
                { text: 'Mountain View, CA', icon: false },
                { text: 'Walnut Creek, CA', icon: false },
                { text: 'Concord, CA', icon: false },
              ]" :key="`detail-${index}`"/>
            </ul>

            <ul class="pl-4 py-2">
              <TierDetail :detail="detail" v-for="(detail, index) in [
                { text: 'Menlo Park, CA', icon: false },
                { text: 'Palo Alto, CA', icon: false },
                { text: 'East Palo Alto, CA', icon: false },
                { text: 'San Leandro, CA', icon: false },
                { text: 'Piedmont, CA', icon: false },
                { text: 'Alameda, CA', icon: false },
                { text: 'Cupertino, CA', icon: false },
                { text: 'San Rafael, CA', icon: false },
                { text: 'Los Altos, CA', icon: false },
                { text: 'Tulelake, CA', icon: false }
              ]" :key="`detail-${index}`"/>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-span-3 bg-white flex flex-col items-center gap-8 py-12 rounded-b-lg shadow-sm text-blue-900">
        <div>
          <button @click="state.showingWaitlistModal = true" class="flex items-center py-3 px-4 text-center gap-4 bg-blue-900 hover:bg-blue-900 text-white transition ease-in duration-200 text-base font-semibold shadow-md focus:outline-none rounded-lg">
            City not listed? Join waitlist
            <img class="h-6" src="https://img.icons8.com/material-rounded/48/ffffff/right.png"/>
          </button>
        </div>
      </div>
    </div>



    <!-- Waitlist signup modal -->
    <InputModal v-show="state.showingWaitlistModal" @close="state.showingWaitlistModal = false">
      <template v-slot:header>
        <div class="text-blue-900">
          Join Concierge Waitlist
        </div>
      </template>
      
      <template v-slot:body>
        <div class="py-4 flex flex-col gap-4">
          <div class="cursor-text relative">
            <input required v-model="form.email" id="email" placeholder="Placeholder" type="email" class="floating-input">
            <label for="email">Email</label>
          </div>

          <div class="cursor-text relative">
            <input required v-model="form.city" id="city" placeholder="Placeholder" type="city" class="floating-input">
            <label for="email">City</label>
          </div>
        </div>
      </template>

      <template v-slot:footer>
        <button @click="submitWaitlistForm" class="hover:text-white hover:bg-blue-800 py-2 px-3.5 bg-blue-900 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none rounded-lg">
          Submit
        </button>
      </template>
    </InputModal>

    
    <!-- Footer -->
    <Footer />
  </div>
</template>

<script>
import Menu from "@/components/menu";
import Footer from "@/components/large-footer.vue";
import TierDetail from "@/components/tier-detail.vue";
import InputModal from "@/components/modals/input-modal.vue";

const { subscriptionPlans: { free, basic, pro, concierge1, concierge2, concierge3, lifetime } } = require("@/js/pricing");
const axios = require("axios");

export default {
  components: {
    Menu,
    Footer,
    TierDetail,
    InputModal
  },
  data() {
    return {
      free, 
      basic, 
      pro,
      concierge1, 
      concierge2, 
      concierge3,
      lifetime,

      state: {
        showingWaitlistModal: false
      },

      form: {
        email: null,
        city: null
      }
    }
  },
  methods: {
    goTo(link) {
      window.scrollTo(0, 0);
      this.$router.push(link);
    },

    contactUsPressed() {
      window.open(`mailto:hello@joinhomeplan.com`, '_self');
    },

    async submitWaitlistForm() {
      const { email, city } = this.form;

      if (!email || !city) {
        this.$toast.warning("Please fill in both fields to proceed");
        return
      }

      const submit_toast = this.$toast.info("Submitting form. Please wait...");
      this.state.showingWaitlistModal = false;

      try {
        await axios.post(`${window.api}/users/join_homeplan_care_waitlist`, { email, city });
        
        this.$toast.success("You have successfully been added to the Concierge Waitlist!");
        Object.keys(this.form).forEach(x => this.form[x] = null);

      } catch (error) {
        this.$toast.error("An error occurred while trying to add you to the Concierge Waitlist. Please try again later");
      }

      this.$toast.dismiss(submit_toast);
    }
  }
}
</script>

<style scoped>
header {
  widows: 100%;
  position: fixed;
  z-index: 1000;
}
</style>