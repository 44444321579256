<template>
  <li class="mt-4 flex items-start">
    <div class="flex-shrink-0">
      <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
      </svg>
    </div>
    <p class="ml-3 text-base leading-6 text-gray-700 dark:text-gray-200">
      {{ detail.text }}

      <span v-if="detail.info" @click="state.showingInfoModal = true;" class="cursor-pointer text-gray-400 hover:text-gray-600 focus:outline-none transition duration-200 ease-in-out">
        <i class="fa fa-question-circle" />
      </span>
    </p>

    <!-- Detail info modal -->
    <InfoModal icon="question-circle" v-show="state.showingInfoModal" @close="state.showingInfoModal = false">
      <template v-slot:header>{{ detail.info.header }}</template>
      <template v-slot:body>{{ detail.info.body }}</template>
    </InfoModal>
  </li>
</template>

<script>
import InfoModal from "@/components/modals/info-modal.vue";

export default {
  components: {
    InfoModal
  },
  props: {
    detail: Object
  },
  data() {
    return {
      state: {
        showingInfoModal: false
      }
    }
  }
}
</script>