export const subscriptionPlans = {
  free: {
    isExpanded: false,
    details: [
      { text: 'Bill pay*' },
      { text: '2GB of documents and photos storage' },
      { text: 'Home insights' },
      { text: 'Home history tracker' },
      { text: 'Maintenance tracker' },
      { text: 'Appliance inventory' },
      { text: 'Tools' },
      { text: 'Education' },
      { text: 'Community' },
      { text: 'Expert library' },
      { text: 'Home services' },
      { text: 'Home policy' }
    ]
  },
  
  basic: {
    isExpanded: false,
    details: [
      { text: 'Bill pay access - Pay up to 4 bills' },
      { text: '20GB of documents and photos storage' },
      { text: 'Customer support' },
      { text: 'Annual Home Health Check-in Call' }
    ],
    cartItem: {
      id: '0f7955eb-5f70-4bb6-954a-4926d93d17c9',
      title: "Basic plan", 
      price: 12,
      type: 'SUBSCRIPTION',
      timePeriod: 'MONTHLY',
      stripeID: 'price_1KJNFPALXoPLLqA5DuaZtHoT'
    }
  },
  
  pro: {
    isExpanded: false,
    details: [
      { text: 'Bill pay access - Pay up to 10 bills' },
      { text: '40GB of documents and photos storage' },
      { text: 'Priority customer support' },
      { text: 'Semi-annual Home Health Check-in Call' },
    ],
    cartItem: {
      id: '3d797bed-1979-44e5-b393-2a82c1a1acc3',
      title: "Pro plan", 
      price: 24,
      type: 'SUBSCRIPTION',
      timePeriod: 'MONTHLY',
      stripeID: 'price_1KJNFfALXoPLLqA5Fo5UDCMh'
    }
  },
  
  lifetime: {
    isExpanded: false,
    details: [
      { text: 'Dedicated home manager' },
      { text: 'Home Plan' },
      { text: 'Annual Home Maintenance/Health Review' },
      { text: 'Monthly Home Health Check-in' },
      { text: 'Contractor Hiring + Quality Assurance' },
      { text: 'Insurance Claims (filing, supplements, negotiation)' },
      { text: '24 hr Emergency Assistance' }
    ],
    cartItem: {
      id: 'cfc53bfb-b218-4631-be9a-42400c75de12',
      title: "Lifetime Membership", 
      price: 499,
      type: 'PAYMENT',
      timePeriod: 'LIFETIME'
    }
  },

  concierge1: {
    isExpanded: false,
    details: [
      { text: 'Welcome Kit' },
      { text: 'Personal Home Manager' },
      { text: 'Account Setup & Management' },
      { text: 'Document Digitization' },
      { text: 'Contractor/Maintenance Hiring' },
      { text: '$100 Annual credit towards maintenance and/or jobs' },
      { text: 'Emergency Assistance' },
      { text: 'Priority Support' },
      { text: 'Dedicated Text Help Line' },
      { text: 'Monthly Home Health Check-in Call' },
      { text: 'Homeplan donates 1% of your subscription to a charity of your choice' }
    ],
    cartItem: {
      id: '20147f36-a00e-488d-8e31-cf316f4038b9',
      title: "Concierge (up to 2500 sq. ft)", 
      price: 1000,
      type: 'SUBSCRIPTION',
      timePeriod: 'YEARLY',
      stripeID: 'price_1KT61fALXoPLLqA5bstvjwhB'
    }
  },
  
  concierge2: {
    isExpanded: false,
    details: [
      { text: 'Welcome Kit' },
      { text: 'Personal Home Manager' },
      { text: 'Account Setup & Management' },
      { text: 'Document Digitization' },
      { text: 'Contractor/Maintenance Hiring' },
      { text: '$200 Annual credit towards maintenance and/or jobs' },
      { text: 'Emergency Assistance' },
      { text: 'Priority Support' },
      { text: 'Dedicated Text Help Line' },
      { text: 'Monthly Home Health Check-in Call' },
      { text: 'Homeplan donates 1% of your subscription to a charity of your choice' }
    ],
    cartItem: {
      id: '91e6e4b6-0839-4516-a7cb-22a08586c0cc',
      title: "Concierge (2501 - 4000 sq. ft)", 
      price: 3000,
      type: 'SUBSCRIPTION',
      timePeriod: 'YEARLY',
      stripeID: 'price_1KT61eALXoPLLqA5lc6menjB'
    }
  },

  concierge3: {
    isExpanded: false,
    details: [
      { text: 'Welcome Kit' },
      { text: 'Personal Home Manager' },
      { text: 'Account Setup & Management' },
      { text: 'Document Digitization' },
      { text: 'Contractor/Maintenance Hiring' },
      { text: '$300 Annual credit towards maintenance and/or jobs' },
      { text: 'Emergency Assistance' },
      { text: 'Priority Support' },
      { text: 'Dedicated Text Help Line' },
      { text: 'Monthly Home Health Check-in Call' },
      { text: 'Homeplan donates 1% of your subscription to a charity of your choice' }
    ],
    cartItem: {
      id: '1196532d-6e50-48a7-b869-b186085ece74',
      title: "Concierge (4000+ sq. ft)", 
      price: 5000,
      type: 'SUBSCRIPTION',
      timePeriod: 'YEARLY',
      stripeID: 'price_1KT61fALXoPLLqA5Tb1WLQte'
    }
  }
}